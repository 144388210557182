<template>
    <div class="page_container">
        <div class="query_header">
            <div class="opt_container">
                <el-button class="float_left_btn" type="primary" plain @click="onShowEditRole(null)">
                    新增角色
                </el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="seq" width="80">
                <template slot="header">
                    <el-tooltip class="item" effect="dark" content="列表顺序按照从小到大排列" placement="top">
                        <span>排序 <i class="el-icon-warning" /></span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="roleName" label="角色名称" />
            <el-table-column header-align="center" prop="remark" label="备注" />
            <el-table-column align="center" label="创建时间" width="160" prop="createTime" />
            <el-table-column align="center" width="100" label="操作">
                <template slot-scope="scope">
                    <span v-if="scope.row.roleNo === 'admin'">-</span>
                    <div class="mini_link" v-else>
                        <el-link type="info" @click="onShowEditRole(scope.row)">编辑角色</el-link>
                        <el-link type="primary" @click="onShowEditPermission(scope.row)">权限管理</el-link>
                        <el-popconfirm title="确定删除吗？" @confirm="onDelRole(scope.row)">
                            <el-link slot="reference" type="danger">删除账号</el-link>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <EditRole :isShow="isShowEditRole" :activeItem="activeItem" @onClose="onCloseEditRole" />
        <EditPermission :isShow="isShowEditPermission" :activeItem="activeItem" @onClose="onCloseEditPermission" />
    </div>
</template>
<script>
import EditRole from './components/roleList/EditRole.vue'
import EditPermission from './components/roleList/EditPermission.vue'
import { getStaffRoleList, delStaffRole, saveStaffRole } from '@/js/api/staffApi.js'

export default {
    components: { EditRole, EditPermission },
    data() {
        return {
            tableData: [],

            activeItem: null,
            isShowEditRole: false,
            isShowEditPermission: false,
        }
    },
    created() {
        this.getStaffRoleListApi()
    },
    methods: {
        // 编辑角色
        onShowEditRole(item) {
            this.activeItem = item
            this.isShowEditRole = true
        },
        onCloseEditRole(state) {
            this.activeItem = null
            this.isShowEditRole = false
            if (state) this.getStaffRoleListApi()
        },
        // 编辑角色下权限
        onShowEditPermission(item) {
            this.activeItem = item
            this.isShowEditPermission = true
        },
        onCloseEditPermission(state) {
            this.activeItem = null
            this.isShowEditPermission = false
            if (state) this.getStaffRoleListApi()
        },

        onEditRoleName(item) {
            const _title = item ? `${item.roleName} - 编辑` : '新增角色'
            this.$prompt('', _title, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: item ? item.roleName : '',
                inputValidator: (value) => {
                    if (!value) return '请输入'
                    if (value && !value.trim()) return '请输入'
                    return true
                },
            })
                .then(({ value }) => {
                    saveStaffRole({ ...item, roleName: value.trim() }).then(() => {
                        this.$message.success('修改成功')
                        this.getStaffRoleListApi()
                    })
                })
                .catch(() => {})
        },
        onDelRole(item) {
            delStaffRole({ roleId: item.id }).then(() => {
                this.$message.success('删除成功')
                this.getStaffRoleListApi()
            })
        },

        getStaffRoleListApi() {
            getStaffRoleList({}).then((res) => {
                this.tableData = res.data
            })
        },
    },
}
</script>
