<template>
    <el-dialog :title="title" :visible.sync="isShow" width="420px" @opened="onOpened" :before-close="onClose">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="100px">
            <el-form-item prop="seq" label="排序">
                <el-input-number v-model.trim="formData.seq" :controls="false" :precision="0" :min="1" />
            </el-form-item>

            <el-form-item prop="roleName" label="角色名称">
                <el-input
                    v-model.trim="formData.roleName"
                    :maxlength="64"
                    show-word-limit
                    placeholder="请输入角色名称"
                />
            </el-form-item>
            <el-form-item prop="remark" label="备注">
                <el-input
                    v-model="formData.remark"
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    placeholder="请输入"
                />
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { saveStaffRole } from '@/js/api/staffApi.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',
            formData: null,
            rules: {
                roleName: [{ required: true, message: '请输入角色名称', trigger: 'change' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.title = '新建角色'
            let formData = {
                seq: 1,
                roleName: '',
                remark: '',
            }
            if (this.activeItem) {
                this.title = `${this.activeItem.roleName} - 编辑`
                formData = { ...formData, ...this.activeItem }
            }
            this.formData = formData
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onSubmit() {
            this.$refs.formData.validate(async (valid) => {
                if (valid) {
                    saveStaffRole({ ...this.formData }).then(() => {
                        this.$message.success('编辑成功')
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-select,
.el-input-number {
    width: 100%;
}
</style>
