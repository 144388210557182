<template>
    <el-drawer
        :title="title"
        class="custom_drawer"
        :visible.sync="isShow"
        size="900px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <div class="title">全局功能权限</div>
        <div class="system_module">
            <el-checkbox-group v-model="systemModulePermissions">
                <el-checkbox v-for="item in systemModuleList" :key="item.id" :label="item.id">
                    {{ item.title }}
                </el-checkbox>
            </el-checkbox-group>
        </div>

        <div class="title">系统功能配置</div>
        <el-tree
            ref="tree"
            :data="roleData"
            show-checkbox
            node-key="id"
            :indent="24"
            :check-strictly="treeStrictly"
            :default-checked-keys="defaultPermissions"
            :check-on-click-node="true"
            :default-expand-all="true"
            :expand-on-click-node="false"
            @check="onTreeChange"
        >
            <div class="tree_item" slot-scope="{ node, data }">
                <span class="tree_text">{{ node.label }}</span>
                <div @click.stop="" class="module" v-if="data.moduleList && data.moduleList.length !== 0">
                    <el-checkbox-group v-model="modulePermissions">
                        <el-checkbox
                            :disabled="!node.checked && !node.indeterminate"
                            v-for="item in data.moduleList"
                            :key="item.id"
                            :label="item.id"
                        >
                            {{ item.title }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
        </el-tree>
        <div class="drawer_footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
        </div>
    </el-drawer>
</template>
<script>
import { getStaffPermissions, saveRolePermission } from '@/js/api/staffApi.js'

export default {
    props: ['isShow', 'activeItem'],
    data() {
        return {
            title: '',
            treeStrictly: true,
            roleData: [],
            defaultPermissions: [],
            modulePermissions: [],
            // 系统权限
            systemModulePermissions: [],
            systemModuleList: [],
        }
    },
    methods: {
        onOpened() {
            this.title = '编辑'
            if (this.activeItem) {
                this.title = `${this.activeItem.roleName} - 编辑`
            }

            this.treeStrictly = true
            this.roleData = []
            this.defaultPermissions = []
            this.modulePermissions = []
            this.getStaffPermissionsApi()
        },
        onClose() {
            this.$emit('onClose')
        },

        onTreeChange() {
            const nodes = this.$refs.tree.getCheckedNodes(false, true)
            const keys = nodes.map((k) => k.id)
            this.permission.forEach((item) => {
                if (!keys.includes(item.parentId) && item.type === 'module') {
                    this.modulePermissions = this.modulePermissions.filter((i) => {
                        return i != item.id
                    })
                }
            })
        },
        onSubmit() {
            const node = this.$refs.tree.getCheckedNodes(false, true)
            let _permissions = node.map((i) => i.id)
            _permissions = [..._permissions, ...this.modulePermissions, ...this.systemModulePermissions]

            saveRolePermission({ roleId: this.activeItem.id, permissionIds: _permissions }).then(() => {
                this.$message.success('编辑成功')
                this.onClose()
            })
        },
        getStaffPermissionsApi() {
            Promise.all([getStaffPermissions({ roleId: this.activeItem.id }), getStaffPermissions({})]).then((res) => {
                res[0]['data'].forEach((item) => {
                    if (item.type === 'route') {
                        this.defaultPermissions.push(item.id)
                    } else if (item.type === 'module') {
                        this.modulePermissions.push(item.id)
                    } else if (item.type === 'system-module') {
                        this.systemModulePermissions.push(item.id)
                    }
                })
                // 初始化列表
                const _routeList = [{ id: 0, title: '' }]
                const _moduleMap = {}
                const _systemModuleList = []
                res[1].data.forEach((item) => {
                    if (item.type === 'route') _routeList.push(item)
                    if (item.type === 'module') {
                        if (!_moduleMap[item.parentId]) _moduleMap[item.parentId] = []
                        _moduleMap[item.parentId].push(item)
                    }
                    if (item.type === 'system-module') _systemModuleList.push(item)
                })
                const data = this.arrayToTree(_routeList, _moduleMap)
                this.roleData = data || []
                this.permission = res[1].data
                this.systemModuleList = _systemModuleList
                this.$nextTick(() => {
                    this.treeStrictly = false
                })
            })
        },

        arrayToTree(routeList, moduleMap) {
            const nodeMap = {}
            routeList.forEach((item) => {
                const id = item.id
                const parentId = item.parentId
                const node = { ...item, label: item.title }
                if (moduleMap[node.id]) node.moduleList = moduleMap[node.id]
                nodeMap[id] = node
                if (parentId !== null) {
                    const parent = nodeMap[parentId] || {}
                    if (!parent.children) {
                        parent.children = []
                    }
                    parent.children.push(node)
                }
            })
            const root = Object.values(nodeMap).find(function (node) {
                return !nodeMap[node.id].parentId
            })

            return root.children
        },
    },
}
</script>
<style lang="less" scoped>
.title {
    font-size: 14px;
    font-weight: 600;
    color: #000;
    margin-bottom: 18px;
}
.system_module {
    padding-bottom: 18px;
    margin-bottom: 28px;
    border-bottom: 1px solid #efefef;
}
/deep/ .el-tree {
    .el-tree-node__content {
        min-height: 28px;
        height: auto;
    }
}
/deep/ .tree_item {
    display: flex;
    align-items: center;
    .tree_text {
        font-size: 14px;
        color: #000;
        min-width: 72px;
    }
    .module {
        display: flex;
        flex-wrap: wrap;
        margin-left: 32px;
        white-space: break-spaces;
        .el-checkbox {
            margin: 3px 14px 3px 0;
        }
        .el-checkbox__label {
            font-size: 13px;
            line-height: 1em;
            color: #999;
        }
    }
}
</style>
